'use client';

import { DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator } from '@/components/ui/dropdown-menu';
import { Skeleton } from '@/components/ui/skeleton';
import { useLogOut } from '@/src/hooks/log-out';
import type { TeamSchema } from '@interval.so/api/app/team/schemas/team_schema';
import type { UserSchema } from '@interval.so/api/app/user/schemas/user_schema';
import { Link } from 'next-view-transitions';
import { Suspense, use } from 'react';
export function MenuContentAuthed({
  user
}: {
  user: Pick<UserSchema, 'displayName'>;
}) {
  const logOut = useLogOut({
    redirectTo: '/'
  });
  return <>
			<DropdownMenuGroup data-sentry-element="DropdownMenuGroup" data-sentry-source-file="profile-menu.client.tsx">
				<DropdownMenuLabel data-sentry-element="DropdownMenuLabel" data-sentry-source-file="profile-menu.client.tsx">{user.displayName}</DropdownMenuLabel>

				<DropdownMenuSeparator data-sentry-element="DropdownMenuSeparator" data-sentry-source-file="profile-menu.client.tsx" />

				<DropdownMenuItem asChild={true} data-sentry-element="DropdownMenuItem" data-sentry-source-file="profile-menu.client.tsx">
					<Link href='/account' data-sentry-element="Link" data-sentry-source-file="profile-menu.client.tsx">Account settings</Link>
				</DropdownMenuItem>
			</DropdownMenuGroup>

			<DropdownMenuSeparator data-sentry-element="DropdownMenuSeparator" data-sentry-source-file="profile-menu.client.tsx" />

			<DropdownMenuGroup data-sentry-element="DropdownMenuGroup" data-sentry-source-file="profile-menu.client.tsx">
				<DropdownMenuItem disabled={logOut.isPending} onClick={logOut.logOut} data-sentry-element="DropdownMenuItem" data-sentry-source-file="profile-menu.client.tsx">
					Log out
				</DropdownMenuItem>
			</DropdownMenuGroup>
		</>;
}
function DisplayName({
  displayNamePromise
}: {
  displayNamePromise: Promise<TeamSchema['displayName']>;
}) {
  const displayName = use(displayNamePromise);
  return displayName;
}
export function MenuContentGuestAuth({
  displayNamePromise
}: {
  displayNamePromise: Promise<TeamSchema['displayName']>;
}) {
  const logOut = useLogOut({
    redirectTo: '/'
  });
  return <DropdownMenuGroup data-sentry-element="DropdownMenuGroup" data-sentry-component="MenuContentGuestAuth" data-sentry-source-file="profile-menu.client.tsx">
			<DropdownMenuLabel data-sentry-element="DropdownMenuLabel" data-sentry-source-file="profile-menu.client.tsx">
				<Suspense fallback={<Skeleton className='h-6 w-32' />} data-sentry-element="Suspense" data-sentry-source-file="profile-menu.client.tsx">
					<DisplayName displayNamePromise={displayNamePromise} data-sentry-element="DisplayName" data-sentry-source-file="profile-menu.client.tsx" />
				</Suspense>
			</DropdownMenuLabel>

			<DropdownMenuSeparator data-sentry-element="DropdownMenuSeparator" data-sentry-source-file="profile-menu.client.tsx" />

			<DropdownMenuItem disabled={logOut.isPending} onClick={logOut.logOut} data-sentry-element="DropdownMenuItem" data-sentry-source-file="profile-menu.client.tsx">
				Log out
			</DropdownMenuItem>
		</DropdownMenuGroup>;
}